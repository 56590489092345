let $ = jQuery

let _props = {
	'id': '',
	'onToggle': false,
	'selector': false
}

let _svg = `<svg width="20px" height="20px" viewBox="0 0 20 20">
	<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
	    <g class="PacnavToggle-lines" fill="#000000">
	        <rect class="PacnavToggle-line1" x="0" y="4" width="20" height="2"></rect>
	        <rect class="PacnavToggle-line2" x="0" y="9" width="20" height="2"></rect>
	        <rect class="PacnavToggle-line3" x="0" y="9" width="20" height="2"></rect>
	        <rect class="PacnavToggle-line4" x="0" y="14" width="20" height="2"></rect>
	    </g>
	    <g class="PacnavToggle-circles" fill="#000000">
	        <circle class="PacnavToggle-circle1" cx="3" cy="10" r="2"></circle>
	        <circle class="PacnavToggle-circle2" cx="10" cy="10" r="2"></circle>
	        <circle class="PacnavToggle-circle3"cx="17" cy="10" r="2"></circle>
	    </g>
    </g>
</svg>`


export default class Toggle {
	constructor(menu, navItems, props) {
		this.props = Object.assign(_props, props)
		this.state = 'initial'
		this.isDefault = (!this.props.selector)
		this.isActive = false
		
		this.$menu = $(menu)
		this.$navItems = $(navItems).clone()
		this.navItems = []
		
		this.$navItems.each((index, ele) => {
			this.navItems.push({
				$ele: $(ele),
				isVisible: true
			})
		})
		
		if(this.isDefault) {
			this.$toggle = $('<div>').addClass('PacnavToggle').html(_svg).insertAfter($(navItems).last())
			this.$toggleElements = $('[class*=PacnavToggle-]', this.$toggle)
		} else {
			this.$toggle = $('<div>').addClass('PacnavToggle').append($(this.props.selector)).insertAfter($(navItems).last())
		}
		
		this.$toggle.append($('<div>').addClass('PacnavToggle-items').append(this.$navItems))
		this.$toggle.click(this.onToggle.bind(this))
	}
	
	close() {
		//console.log('Toggle.close')
		this.$toggle.removeClass('is-active')
		this.isActive = false
		this.setTransforms()
	}
	
	open() {
		//console.log('Toggle.open')
		this.$toggle.addClass('is-active')
		this.isActive = true
		this.setTransforms()
	}
	
	getEle() {
		return this.$toggle
	}
	
	getWidth() {
		let style = this.$toggle[0].currentStyle || window.getComputedStyle(this.$toggle[0])
		let rect = this.$toggle[0].getBoundingClientRect()
		let margins = (parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10)) || 0
		let width = margins + (rect.width || rect.right - rect.left)
		
		//console.log('Toggle.getWidth', width)
		
		return width
	}
	
	setState(state) {
		if(this.isDefault && state != this.state && this.state != 'initial') {
			this.$toggleElements.each((index, ele) => {
				let label = $(ele).attr('class').match(/PacnavToggle-([^\s]+)/)[1]
			
				$(ele).css({
					animationName: `PacnavToggle--${label}-from-${this.state}-to-${state}`
				})
			})
		}
		
		if(state != this.state) {
			this.close()
			
			if(state == 'mobile') {
				this.setVisible(this.navItems.length)
			}
			
			if(state == 'desktop') {
				this.setVisible(0)
			}
			
			this.setTransforms()
		}
		
		this.$toggle
			.toggleClass('is-desktop', (state != 'mobile' && state != 'intermediary'))
			.toggleClass('is-intermediary', (state == 'intermediary'))
			.toggleClass('is-mobile', (state == 'mobile'))
			
		this.state = state
	}
	
	setTransforms() {
		if(this.isActive) {
			// Transforms are here rather than in CSS to support IE
			$('.PacnavToggle-lines', this.$toggle)[0].setAttribute('transform', 'rotate(90 10 10)')
			$('.PacnavToggle-line1', this.$toggle)[0].setAttribute('transform', 'translate(0 5)')	
			$('.PacnavToggle-line2', this.$toggle)[0].setAttribute('transform', 'rotate(-45 10 10)')
			$('.PacnavToggle-line3', this.$toggle)[0].setAttribute('transform', 'rotate(45 10 10)')	
			$('.PacnavToggle-line4', this.$toggle)[0].setAttribute('transform', 'translate(0 -5)')
		} else {
			$('.PacnavToggle-lines, .PacnavToggle-line1, .PacnavToggle-line2, .PacnavToggle-line3, .PacnavToggle-line4').removeAttr('transform')
		}
	}
	
	onToggle(e) {
		//console.log('Toggle.onToggle')
		
		e.stopPropagation()
		
		if(e.currentTarget == this.$toggle[0]) {
			this.$toggle.toggleClass('is-active')
			this.isActive = !this.isActive
			this.setTransforms()
		}
		
		if(typeof this.props.onToggle == 'function') {
			this.props.onToggle()
		}
	}
	
	setVisible(numberVisible) {
		//console.log('Toggle.setVisible', numberVisible)
		//console.log('-----------------')
		
		for(let i=0; i<this.navItems.length; i++) {
			let item = this.navItems[this.navItems.length - 1 - i]
			
			if(i < numberVisible && !item.isVisible) {
				item.isVisible = true
				item.$ele.show()
			}
			
			if(i >= numberVisible && item) {
				item.isVisible = false
				item.$ele.hide()
			}
		}
	}
}
