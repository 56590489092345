let $ = jQuery


$('a').each((index, ele) => {
	
	if(ele.hostname != location.hostname) {
		$(ele).attr('target', '_blank')
	}
	
})
