import MsPacnav from '../pacnav/ycf/ms-pacnav'

let $ = jQuery
let $window = $(window)
let $body, $nav, $navItems

let _currentLevel = 0
let _windowWidth = $window.width()
let _isMobile = (_windowWidth < 640)


function onClick(e) {
	if(_isMobile) {

		let level = ($(this).parentsUntil('.Pacnav', '.hs-item-has-children').length)
		if(level == _currentLevel && e.pageX > 50) {
			return
		} else if(level == _currentLevel) {			
			e.preventDefault()
			e.stopPropagation()
			
			level = _currentLevel - 1
			$(this).parentsUntil('.Pacnav', '.hs-item-has-children').eq(1).addClass('is-active')
			$(this).parent().removeClass('is-active')
		} else {			
			e.preventDefault()
			e.stopPropagation()
			
			$(this).parent().addClass('is-active')
		}
		
		$body.removeClass(`Menu--is-level${_currentLevel}`)
		$body.addClass(`Menu--is-level${level}`)
		
		$nav.removeClass(`is-level${_currentLevel}`)
		$nav.addClass(`is-level${level}`)
		
		_currentLevel = level
	}
}

function onToggle(menu) {
	if(menu == 'primary-nav' && _currentLevel > 0) {
		$body.removeClass(`Menu--is-level${_currentLevel}`)
		$body.addClass(`Menu--is-level0`)
		
		$nav.removeClass(`is-level${_currentLevel}`)
		$nav.addClass(`is-level0`)
		
		$navItems.removeClass('is-active')
		
		_currentLevel = 0
	}
}

function onResize() {
	// console.log('MobileMenu.onResize')
	
	_windowWidth = $window.width()
	
	if(_windowWidth < 640 && !_isMobile) {
		_isMobile = true
		_currentLevel = 0
	}
}


class MobileMenu {
	constructor() {

		$body = $('body')
		$nav = $('.Header-nav > .Pacnav')
		$navItems = $('.Header-nav .hs-item-has-children')
		
		$('> a', $navItems).on('click', onClick)
		$window.on('resize', onResize)
		
		$body.addClass(`Menu--is-level${_currentLevel}`)
		$nav.addClass(`is-level${_currentLevel}`)
		
		MsPacnav.on('toggle', onToggle)
		
	}
}


export default new MobileMenu()
