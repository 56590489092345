let $ = jQuery
let _speed = 3000
let _registered = []


$(document).on('ready', () => {
	for(let item of _registered) {
		item.init()
	}
})


class LogoRow {
	constructor(ele) {
		this.$ele = $(ele)
		this.$wrap = $('.LogoRow-wrap', this.$ele)
		this.$logos = $('.LogoRow-item', this.$ele)
		_registered.push(this)
	}
	
	init() {
		for(let i = 0; i < 3; i++) {
			this.$logos.each((index, ele) => {
				$(ele).clone().appendTo(this.$wrap)
			})
		}
		
		this.$ele.addClass('is-active')
		this.$wrap.css({
			'animation-duration': `${this.$logos.length * (_speed / 1000)}s`
		})
	}
}


$(() => $('.LogoRow').each((index, ele) => new LogoRow(ele)))
