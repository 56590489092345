let $ = jQuery


$('a[href*="#"]').on('click', e => {

	if($(e.target).parents('div#hs_menu_wrapper_SideBarMenu').length == 0) {

	    if(typeof e.target.hash !== typeof undefined) {
	    	var url_arr = $(location).attr('href').split('#')
			var href_url_arr = e.target.href.split('#')

			if(url_arr[0] == href_url_arr[0]) {

				console.log('1. url : ' + url_arr[0]);
				console.log('2. href_url : ' + href_url_arr[0])

		        let target = e.target.hash.slice(1)
		        let $ele = $(`#${target}`)
		        let headerOffset = -80
		        if($('body>.Page.LP-Basic').length > 0) {
		            headerOffset = 0;
		        }
		        
		        if($ele.length) {
		            e.preventDefault()
		        
		            let offset = $ele.offset().top + headerOffset
		            
		            $('html, body').animate({
		                scrollTop: `${offset}px`
		            }, 400)
		            
		            if(target.slice(0, 6) != 'block-' && 'pushState' in history) {
		                history.pushState({}, '', e.target.href)
		            }
		        }
		    }
	    }
	    
	}
    
})