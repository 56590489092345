let $ = jQuery
let $body = $('body')
let $document = $(document)
let $window = $(window)

let _isResizing = false
let _resizeTimeouts = []
let _registered = []
let _windowWidth = $window.width()


$document.on('visibilitychange', () => {
	if(!document.hidden) {
		onOrientationChange()
	}
})

$window.on('resize', () => {
	onResize()
	
	if(_resizeTimeouts.length) {
		for(let timeout of _resizeTimeouts) {
			window.clearTimeout(timeout)
		}
		
		_resizeTimeouts = []
	}
	
	for(let i = 1; i <= 10; i++) {
		_resizeTimeouts.push(window.setTimeout(onResize, 50 * i))
	}
})

$window.on('orientationchange', onOrientationChange)

$(() => $('.ImageGallery').each((index, ele) => new ImageGallery(ele)))


function onOrientationChange() {
	if(!_isResizing) {
		_isResizing = true
		
		for(let i = 1; i <= 10; i++) {
			window.setTimeout(() => {
				onResize()
			}, 50 * i)
		}
		
		window.setTimeout(() => _isResizing = false, 500)
	}
}


function onResize() {
	_windowWidth = $window.width()
	
	for(let gallery of _registered) {
		gallery.onResize()
	}
}


class ImageGallery {
	constructor(ele, props) {
		
		this.props = Object.assign({
			'selector': '> img',
			'animationSpeed': 800,
			'aspectRatio': '5x3',
			'gutterWidth': {'small': 10, 'medium': 20, 'large': 30},
			'itemsPerRow': {'small': 2, 'medium': 3, 'large': 4},
			'breakpoints': {'small': 640, 'medium': 960}
		}, props)
		
		this.currentlySelected = null
		
		this.$ele = $(ele)
		
		$(this.props.selector, this.$ele).each((index, ele) => {
			$(ele).wrap('<div class="ImageGallery-item">')
		})
		
		this.$items = $('.ImageGallery-item', this.$ele)
		
		this.attach()
		this.setupLayout()
		this.coordinateLayout()
		
		_registered.push(this)
	}
	
	attach() {
		this.$items.on('click', this.onClick.bind(this))
	}
	
	setupLayout() {
		let gutterWidth = this.getGutterWidth()
		
		this.$ele
			.addClass('is-active')
			.css({
				'display': 'block',
				'position': 'relative',
			})
			
		this.$items.each((index, ele) => {
			$(ele)
				.data('index', index)
				.css({
					'position': 'absolute'
				})
			
			$(this.props.selector, ele).css({
				'display': 'block',
				'height': '100%',
				'width': '100%'
			})
		})
	}
	
	coordinateLayout() {
		let aspectRatio = this.getAspectRatio()
		let gutterWidth = this.getGutterWidth()
		let itemsPerRow = this.getItemsPerRow()
		
		let itemCount = this.$items.length
		let itemWidth = Math.round(10000 / itemsPerRow) / 100
		let itemWidthPx = (this.getWidth() / itemsPerRow) - gutterWidth
		let itemHeight = (itemWidthPx / aspectRatio[0]) * aspectRatio[1]
		
		let placeholderWidth = this.getWidth() - gutterWidth
		let placeholderHeight = (placeholderWidth / aspectRatio[0]) * aspectRatio[1]
		let placeholderOffset = placeholderHeight + gutterWidth
		
		let totalRows = Math.ceil(itemCount / itemsPerRow)
		
		if(this.currentlySelected === null) {
			placeholderOffset = 0
		}
		
		this.$items.each((index, ele) => {
			let column, row, thisLeft, thisHeight, thisTop, thisWidth
			
			if(this.currentlySelected !== null && index > this.currentlySelected) {
				column = ((index - 1) % itemsPerRow)
				row = Math.floor((index - 1) / itemsPerRow)
			} else {
				column = (index % itemsPerRow)
				row = Math.floor(index / itemsPerRow)
			}
			
			if(index === this.currentlySelected) {
				thisLeft = `${gutterWidth / 2}px`
				thisHeight = `${placeholderHeight}px`
				thisTop = 0
				thisWidth = `calc(100% - ${gutterWidth}px)`
			} else {
				thisLeft = `calc(${column * itemWidth}% + ${gutterWidth / 2}px)`
				thisHeight = `${itemHeight}px`
				thisTop = `${placeholderOffset + (itemHeight + gutterWidth) * row}px`
				thisWidth = `calc(${itemWidth}% - ${gutterWidth}px)`
			}
			
			$(ele).css({
				'height': thisHeight,
				'left': thisLeft,
				'position': 'absolute',
				'top': thisTop,
				'width': thisWidth
			})
		})
		
		this.$ele.css({
			'height': `${placeholderOffset + totalRows * (itemHeight + gutterWidth) - gutterWidth}`,
			'margin': `0 ${gutterWidth / -2}px`,
			'width': `calc(100% + ${gutterWidth})`
		})
	}
	
	getAspectRatio() {
		let ratio = this.props.aspectRatio.split('x')
		return ratio.slice(0, 2)
	}
	
	getBreakpoint() {
		if(_windowWidth < this.props.breakpoints.small) {
			return 'small'
		} else if(_windowWidth < this.props.breakpoints.medium) {
			return 'medium'
		} else {
			return 'large'
		}
	}
	
	getGutterWidth() {
		return parseInt(this.props.gutterWidth[this.getBreakpoint()])
	}
	
	getItemsPerRow() {
		return parseInt(this.props.itemsPerRow[this.getBreakpoint()])
	}
	
	getWidth() {
		return this.$ele.outerWidth()
	}
	
	onClick(e) {
		e.preventDefault()
		
		this.$ele.removeClass('is-resizing')
		this.$items.eq(this.currentlySelected).removeClass('is-active')
		this.currentlySelected = $(e.currentTarget).data('index')
		this.$items.eq(this.currentlySelected).addClass('is-active')
		
		this.coordinateLayout()
	}
	
	onResize() {
		this.coordinateLayout()
		this.$ele.addClass('is-resizing')
	}
}


export default function registerImageGallery(selector, props) {
	$(selector).each((index, ele) => new ImageGallery(ele, props))
}
