import {requestAnimationFrame} from 'animation-frame-polyfill'


let $ = jQuery
let $window = $(window)

let _isResizing = false
let _resizeTimeouts = []
let _registered = []
let _start
let _scrollTop = $window.scrollTop()
let _windowHeight = $window.height()


$window.on('scroll resize orientationchange', onScroll)


function tick(time) {
	_start = _start || time
	
	if((time - _start) > 4000) {
		_start = time
		popQuestions()
	}
	
	requestAnimationFrame(tick)
}

function popQuestions() {
	for(let valueStatement of _registered) {
		valueStatement.popQuestion()
	}
}

function onOrientationChange() {
	if(!_isResizing) {
		_isResizing = true
		
		for(let i = 1; i <= 10; i++) {
			window.setTimeout(() => {
				onResize()
			}, 50 * i)
		}
		
		window.setTimeout(() => _isResizing = false, 500)
	}
}

function onScroll() {
	_scrollTop = $window.scrollTop()
	
	for(let valueStatement of _registered) {
		valueStatement.initializeOnScroll()
	}
}

function onResize() {
	_windowHeight = $window.height()
	
	for(let valueStatement of _registered) {
		valueStatement.initializeOnScroll()
	}
}


class ValueStatement {
	constructor(ele) {
		this.$ele = $(ele)
		this.questionEles = []
		this.questionEles.push($('.ValueStatement-question[data-position=1]', this.$ele))
		this.questionEles.push($('.ValueStatement-question[data-position=2]', this.$ele))
		this.questionEles.push($('.ValueStatement-question[data-position=3]', this.$ele))
		
		this.initialized = false
		this.randomGroup = 0
		this.randomChild = [0, 0, 0]
		this.popHistory = []
		
		_registered.push(this)
	}
	
	popQuestion() {
		if(this.initialized) {
			let lastGroup = this.randomGroup
			let thisGroup = (lastGroup + Math.ceil(Math.random() * 2)) % 3 || 0
			
			// Ensure groups don't repeat the last two iterations
			if(this.popHistory.indexOf(thisGroup) != -1) {
				thisGroup = 3 - this.popHistory.reduce((a, b) => a + b)
			}
			
			this.popHistory.push(thisGroup)
			this.popHistory = this.popHistory.slice(-2)
			
			let lastChild = this.randomChild[thisGroup]
			let thisChild = (lastChild + Math.ceil((Math.random() * this.questionEles[thisGroup].length - 1))) % this.questionEles[thisGroup].length || 0
			
			let $lastQuestion = $(this.questionEles[thisGroup][lastChild])
			let $thisQuestion = $(this.questionEles[thisGroup][thisChild])
			
			$lastQuestion.removeClass('is-active')
			$thisQuestion.addClass('is-active')
				
			this.randomGroup = thisGroup
			this.randomChild[thisGroup] = thisChild
		}
	}
	
	initializeOnScroll() {
		if(!this.initialized && this.$ele.offset().top < _scrollTop + (_windowHeight * 0.6)) {
			this.initialized = true
			this.popQuestion()
			window.setTimeout(this.popQuestion.bind(this), 200)
			window.setTimeout(this.popQuestion.bind(this), 400)
		}
	}
}


$(() => $('.ValueStatement').each((index, ele) => new ValueStatement(ele)))
requestAnimationFrame(tick)
