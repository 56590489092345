let _isActive = false
let _timer

let $ = jQuery
let $window = $(window)
let $form = $('.GlobalSearch')
let $field = $('.GlobalSearch-field')
let $toggle = $('.GlobalSearch-open, .GlobalSearch-close')
let $input = $('input[type=text]', $form)
let $submit_url = '/search'


if(window.location.pathname.match(/^\/search/)) {
	_isActive = true; 
	
	$form.addClass('is-active');
	$form.addClass('has-noTransition');
	
	if(_timer) {
		window.clearTimeout(_timer);
	}
	
	_timer = setTimeout(() => {
		$form.removeClass('has-noTransition');
		$form.removeClass('is-transitioning');
	}, 200);

	var url = $(location).attr('href');
	if(url.indexOf('q=') != -1) {
	    var arr_url = url.split('q=');
	    var key = arr_url[arr_url.length - 1];

	    $('#edit-term').val(key);
	  }
}


$form.on('submit', (e) => {
	$submit_url = $form.find('#header-search-form').attr('action');

	e.preventDefault()
	window.location = `${$submit_url}?q=${encodeURIComponent($input.val())}`
})

$window.on('resize', () => {
	$input.blur()
		
	if($window.width() < 640 && _isActive) {
		$form.removeClass('is-active')
		$open.removeClass('is-active')
		_isActive = false
	}
})

$toggle.on('click', (e) => {
	e.preventDefault()
	e.stopPropagation()
	
	if($window.width() < 640) {
		return
	}
	
	_isActive = !_isActive
	
	$form.toggleClass('is-active', _isActive)
	$form.addClass('is-transitioning')
	
	if(_isActive) {
		$input.focus()
	}
	
	if(_timer) {
		window.clearTimeout(_timer)
	}
	
	_timer = setTimeout(() => {
		$form.removeClass('has-noTransition')
		$form.removeClass('is-transitioning')
	}, 200)
})
