/**
 * Pacnav v1.2 (YCF)
 *
 * A menu compression script that provides variable navigation layout. Pacnav consists of 2 primary elements, a
 * desktop ul (Pacnav--desktop) and a mobile ul (Pacnav--mobile) that are displayed in varying state to create a
 * single fluid navigation.
 *
 * DESKTOP STATE (Pacnav--is-desktop): full desktop nav is visible and the full mobile nav is hidden.
 *
 * INTERMEDIATE STATE (Pacnav--is-intermediary): Pacnav "moves" menu items (by hiding in desktop, and showing in
 * mobile) from the full nav (Desktop) to the compressed nav (Mobile).
 *
 * MOBILE STATE (Pacnav--is-mobile): mobile nav is displayed by clicking the menu toggle (hamburger menu) the
 * desktop nav is hidden
 *
 * FIXED ELEMENTS: items in the desktop nav that should always show (in intermediate and mobile states) these
 * are very useful for CTAs that should (almost) always be available.
 *
 * NOTE: If you experience unexpected behavior with Pacnav at intermediate breakpoints the cause is usually CSS
 * changes to the dom elements. For example, hiding elements (CTA, etc.) within the nav can cause calculations
 * to change making Pacnav "jump" between intermediary and mobile as the element is hidden/shown.
 *
 * To debug Pacnav start with your CSS!
 *
 */

import MsPacnav from './ms-pacnav'
import Toggle from './toggle'

var _menu_grid_url = 'null'

let $ = jQuery
let $body = $('body')
let $document = $(document)
let $window = $(window)

let _props = {
	'minVisible': 2,
	'mobileWidth': false,
	'scrollOffset': 0,
	'childSelector': '> *',
	'parentSelector': 'body',
	'menuToggleSelector': false,
	'transitionInLength': 400,
	'transitionOutLength': 1100
}

let _isResizing = false
let _resizeTimeouts = []
let _registered = []
let _transitionTimeout
let _msPacnav = MsPacnav
let _windowWidth = $window.width()
let _isMergeNav = true


$document.on('readystatechange', () => {
	if(document.readyState == 'complete') {
		onReady()
	}
})

$document.on('visibilitychange', () => {
	if(!document.hidden) {
		onOrientationChange()
	}
})

$window.on('resize', () => {
	onResize()
	
	if(_resizeTimeouts.length) {
		for(let timeout of _resizeTimeouts) {
			window.clearTimeout(timeout)
		}
		
		_resizeTimeouts = []
	}
	
	for(let i = 1; i <= 10; i++) {
		_resizeTimeouts.push(window.setTimeout(onResize, 50 * i))
	}
})

$body.on('click', onClickBody)
$window.on('orientationchange', onOrientationChange)
$window.on('scroll', onScroll)



function onOrientationChange() {
	if(!_isResizing) {
		_isResizing = true
		
		for(let i = 1; i <= 10; i++) {
			window.setTimeout(() => {
				onResize()
			}, 50 * i)
		}
		
		window.setTimeout(() => _isResizing = false, 500)
	}
}


function onReady() {

	// Trasform HB Menu to Pacnav menu
	$('#hs_menu_wrapper_main_nav ul').each(function() {
		if(!$(this).hasClass('menu')) {
			$(this).addClass('menu');
		}
	});

	$('#hs_menu_wrapper_main_nav li').each(function() {
		if($(this).hasClass('hs-item-has-children') && !$(this).hasClass('expanded')) {
			$(this).addClass('expanded');
		} else if(!$(this).hasClass('hs-item-has-children') && !$(this).hasClass('leaf')) {
			$(this).addClass('leaf'); 
		}

		if($(this).hasClass('active')) {
			var $link_tag = $(this).find('>a');
			if(!$link_tag.hasClass('active')) {
				$link_tag.addClass("active-trail active");
			}
		}
	});


	// Merge menu
	
	if(_isMergeNav) {
		console.log('_windowWidth : ' + _windowWidth);
		if(_windowWidth < 820) {
			mergeItemsMenu(true);
		}
	}
	

	onLoadItemsMenu();

	// End Trasform HB Menu to Pacnav menu

	$('.Pacnav').each(function() {
		new Pacnav(this)
	})
	
	for(let menu of _registered) {
		menu.init()
		menu.eatPellets()
		menu.onScroll()
		_msPacnav.onReady(menu)
	}
}

var topNavHTML = '';

function mergeItemsMenu(isMerge) {

	if(isMerge) {

		if(!$('#primary-nav').hasClass('merged')) {
			console.log('Merge menu function called.');

			if(topNavHTML == '') {

				$('.Global-Menu ul.menu li').each(function() {
					topNavHTML += '<li class="marged-item hs-menu-item hs-menu-depth-1 leaf">'+$(this).html()+'</li>\n';
				});
			} 

			if($('#primary-nav #hs_menu_wrapper_main_nav>ul .marged-item').length == 0) {
				$('#primary-nav #hs_menu_wrapper_main_nav>ul').prepend(topNavHTML);
			}

			$('#primary-nav').addClass('merged');
		}

		if($('.Header-Global #hs_menu_wrapper_main_nav').is(':visible')) {
			$('.Header-Global #hs_menu_wrapper_main_nav').hide();
		}

	} else { //!isMerge

		if($('#primary-nav').hasClass('merged')) {

			if($('#primary-nav #hs_menu_wrapper_main_nav>ul .marged-item').length > 0) {
				$('#primary-nav #hs_menu_wrapper_main_nav>ul .marged-item').remove();
			}

			$('#primary-nav').removeClass('merged');
		}

		if(!$('.Header-Global #hs_menu_wrapper_main_nav').is(':visible')) {
			$('.Header-Global #hs_menu_wrapper_main_nav').show();
		}
	}
}


function onLoadItemsMenu() {
	$('#hs_menu_wrapper_main_nav li.hs-menu-depth-1>a').each(function() {
		var href = $(this).attr('href');
		var menu_grid_url = _menu_grid_url;

		if(href == menu_grid_url) {
			$(this).parent().addClass('li-product');
		}
	});
}


function onClickBody(e) {
	for(let menu of _registered) {
		if(menu.state != 'mobile' && menu.toggle.isActive) {
			menu.toggle.close()
		}
	}
}


function onResize() {
	_windowWidth = $window.width()

	if(_isMergeNav) {
		if(_windowWidth < 820) {
			mergeItemsMenu(true)
		} else {
			mergeItemsMenu(false)
		}
	}
	
	for(let menu of _registered) {
		menu.eatPellets()
	}
}


function onScroll() {
	for(let menu of _registered) {
		menu.onScroll()
    }
}


export default class Pacnav {
	constructor(ele, props) {
		//console.log('Menu.constructor', ele, props)
		//console.log('----------------')
		
		_registered.push(this)
		
		this.$ele = $(ele)
		this.$navItems = null
		
		this.state = 'desktop'
		this.props = Object.assign(_props, props || {})
		this.id = this.$ele.attr('id')
		this.isActive = false
		
		if(!this.id) {
			this.id = `pacnav-${_registered.length}`
			this.$ele.attr('id', this.id)
		}
		
		for(let key in _props) {
			let lowercaseKey = key.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`)
			
			if(this.$ele.data(`pacnav-${lowercaseKey}`)) {
				this.props[key] = this.$ele.data(`pacnav-${lowercaseKey}`)
			}
		}
	}
	
	init() {
		//console.log('Menu.init')
		//console.log('---------')
		
		this.$navItems = $(this.props.childSelector, this.$ele)
		
		if(this.props.parentSelector) {
			this.$parent = $(this.props.parentSelector)
		} else {
			this.$parent = this.$ele.parent()
		}
		
		this.toggle = new Toggle(this.$ele, this.$navItems.filter(':not(.is-fixed)'), {
			'id': this.id,
			'onToggle': this.onToggle.bind(this),
			'selector': this.props.menuToggleSelector
		})
		
		this.fixedItemsWidth = 0
		this.unfixedItemsWidth = 0
		this.navItems = []
		
		this.$navItems.each((index, ele) => {

			var liClass = $(ele).attr('class')
			if(liClass.indexOf('marged-item') == -1) {

				let style = ele.currentStyle || window.getComputedStyle(ele)
				let rect = ele.getBoundingClientRect()
				let margins = (parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10)) || 0
				let width = margins + (rect.width || rect.right - rect.left)
				
				// update nav object
				this.navItems.push({
					$ele: $(ele),
					isFixed: $(ele).hasClass('is-fixed'),
					isVisible: true,
					width: width
				})
				
				if($(ele).hasClass('is-fixed')) {
					this.fixedItemsWidth += width
				} else {
					this.unfixedItemsWidth += width
				}
			}
		})
		
		let style = this.$ele[0].currentStyle || window.getComputedStyle(this.$ele[0])
		let rect = this.$ele[0].getBoundingClientRect()
		let margins = (parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10)) || 0
		let menuWidth = margins + (rect.width || rect.right - rect.left)
		let availableWidth = menuWidth - this.fixedItemsWidth
		let calculatedWidth = 0
		let visibleItems = 0
		
		for(let i in this.navItems) {
			let item = this.navItems[i]
			
			if(!item.isFixed) {
				calculatedWidth += item.width
			}
			
			if(calculatedWidth <= availableWidth) {
				visibleItems++
			}
		}
		
		if(visibleItems < this.navItems.length) {
			this.state = 'intermediary'
		}
		
		this.$parent.addClass('Pacnav--is-loaded')
	}
	
	onScroll() {
		let isScrolling = ($(window).scrollTop() > this.props.scrollOffset)
		this.$parent.toggleClass('Pacnav--is-scrolling', isScrolling)
		this.$ele.toggleClass('is-scrolling', isScrolling)
	}
	
	onToggle() {
		this.isActive = !this.isActive
		
		this.$ele.toggleClass('is-active')
		this.$parent.toggleClass('Pacnav--is-active')
		
		this.$parent.addClass('Pacnav--is-transitioning')		
	
		if(_transitionTimeout) {
			window.clearTimeout(_transitionTimeout)
		}
		
		_transitionTimeout = window.setTimeout(() => {
			this.$parent.removeClass('Pacnav--is-transitioning')
		}, this.isActive ? this.props.transitionInLength : this.props.transitionOutLength)
		
		_msPacnav.onToggle(this)
	}

	setState(state) {
		//console.log('Menu.setState', state, this.state)
		//console.log(this.state != state)
		//console.log(this.isActive)
		
		if(this.state != state && this.isActive) {
			this.isActive = false	
				
			this.$parent.removeClass('Pacnav--is-active')
			this.$ele.removeClass('is-active')
		
			this.$parent.addClass('Pacnav--is-transitioning')
			
			if(_transitionTimeout) {
				window.clearTimeout(_transitionTimeout)
			}
			
			_transitionTimeout = window.setTimeout(() => this.$parent.removeClass('Pacnav--is-transitioning'), this.props.transitionOutLength)
			
			this.toggle.close()
		}
		
		this.$parent
			.toggleClass('Pacnav--is-desktop', (state != 'mobile' && state != 'intermediary'))
			.toggleClass('Pacnav--is-intermediary', (state == 'intermediary'))
			.toggleClass('Pacnav--is-mobile', (state == 'mobile'))
		
		this.$ele
			.toggleClass('is-desktop', (state != 'mobile' && state != 'intermediary'))
			.toggleClass('is-intermediary', (state == 'intermediary'))
			.toggleClass('is-mobile', (state == 'mobile'))
			
		this.state = state
	}
	
	/**
	 * eatPellets()
	 *
	 * This is where the magic happens:
	 * - Loop all unfixed items, toggle visibility based on available space
	 * - Set menu state based on calculations (desktop, intermediary, or mobile)
	 * - Calculate positioning based on updates to nav
	 */
	eatPellets() {
		//console.log('Menu.eatPellets')
		//console.log('---------------')
		
		let style = this.$ele[0].currentStyle || window.getComputedStyle(this.$ele[0])
		let rect = this.$ele[0].getBoundingClientRect()
		let margins = (parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10)) || 0
		let menuWidth = margins + (rect.width || rect.right - rect.left)
		let availableWidth = menuWidth - this.fixedItemsWidth
		let calculatedWidth = 0
		let visibleItems = 0
		
		if(this.state != 'desktop') {
			availableWidth -= this.toggle.getWidth()
		}
	
		// loop all unfixed nav items
		for(let i in this.navItems) {
			let item = this.navItems[i]
			
			if(!item.isFixed) {
				calculatedWidth += item.width
			}
			
			let isVisible = (calculatedWidth <= availableWidth) || (this.state != 'desktop' && i == this.navItems.length - 1 && calculatedWidth <= availableWidth + this.toggle.getWidth())
			
			//console.log(isVisible, item.isVisible)
			
			if(isVisible != item.isVisible) {
				item.$ele.toggle(isVisible)
				item.isVisible = isVisible
			}
			
			if(isVisible) {
				visibleItems++
			}
		}
	
		// if the number of visible items is less than the minimum, and our state isn't mobile, set to mobile
		let isMobile = (visibleItems < this.props.minVisible || (this.props.mobileWidth && _windowWidth < this.props.mobileWidth))
		let isIntermediary = (visibleItems < this.navItems.length && !isMobile)
		let isDesktop = (!isMobile && !isIntermediary)

		if(isMobile) {
			this.setState('mobile')
			this.toggle.setState('mobile')
		} else if(isIntermediary) {
			this.setState('intermediary')
			this.toggle.setState('intermediary')
			this.toggle.setVisible(this.navItems.length - visibleItems)
		} else {
			this.setState('desktop')
			this.toggle.setState('desktop')
		}
		
		//console.log('Available width: ', availableWidth)
		//console.log('Calculated width: ', calculatedWidth)
		//console.log('Visible items: ', visibleItems)
		//console.log('Is mobile: ', isMobile)
	}
}
