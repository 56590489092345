/* ===================================================================
SearchTop
=================================================================== */
function redirectGlobalTopSearch(key_search_input) {
    var path_url = window.location.href;
    path_url = path_url.replace('https://','').replace('http://','');
    
    /*
    var url_items = path_url.split('/');
    var solution_url = url_items[1];
    if(solution_url.indexOf('#')) {
        solution_url = solution_url.split('#')[0];
    }
    var key_search = encodeURIComponent(key_search_input);
    var redirect_url = '/' + solution_url + '/search?q=' + key_search;
    window.location.href = redirect_url;
    */
    var url_items = path_url.split('/');
    var key_search = encodeURIComponent(key_search_input);
    var redirect_url = '/search?q=' + key_search;
    if(url_items.length > 1) {
        var lang_code = url_items[1];
        if(lang_code == 'en' || lang_code == 'cn') {
            redirect_url = '/' + lang_code + redirect_url;
        }
    }
    window.location.href = redirect_url; 
}

$(function() {
    if($('.Header-Global .SearchTop').length > 0) {

        var url = $(location).attr('href');
        var current_key = '';
        if(url.indexOf('search?q=') != -1) {
            var arr_url = url.split('q=');
            current_key = arr_url[arr_url.length - 1];
            current_key = decodeURIComponent(current_key);
            $('.Header-Global .SearchTop .SearchTop-input').val(current_key);
            $('.Header-Global .SearchTop .SearchTop-input').addClass('show');
        }

        $('.Header-Global .SearchTop .SearchTop-button').click(function() {
            var $textBox = $(this).parent().find('#txtSearch');
            if($textBox.hasClass('show')) {
                if($textBox.val() != '') {
                    if($textBox.val() != current_key) {
                        redirectGlobalTopSearch($textBox.val());
                    }
                } else {
                    $textBox.removeClass('show');
                    $textBox.blur(); 
                } 
            } else {
                $textBox.addClass('show');
                $textBox.focus();
            }

            return false;
        });

        $('.Header-Global .SearchTop .SearchTop-input').blur(function() {
            if($(this).val() == '') {
                $(this).removeClass('show');
            }
        });

        $('.Header-Global .SearchTop .SearchTop-input').keypress(function (event) {
            if($(this).val() != '') {
                var keycode = (event.keyCode ? event.keyCode : event.which);
                if (keycode == '13') {
                   redirectGlobalTopSearch($(this).val());
                }
                event.stopPropagation();
            }
        });
    }
});

/* ===================================================================
ページトップ
=================================================================== */
$(function() {

    if($('#pagetop').length > 0) {
        var topBtn = $('#pagetop');
        topBtn.hide();
        $(window).scroll(function() {
            if ($(this).scrollTop() > 100) {
                topBtn.fadeIn();
            } else {
                topBtn.fadeOut();
            }
        });
        topBtn.click(function() {
            $('body,html').animate({
                scrollTop: 0
            }, 500);
            return false;
        });
    }
});

$(function() {
    if($('.LP-FAQ').length > 0) {
        var faqTrigger = $('.LP-FAQ .FAQ-title');
        var faqTrigger_arrow = $('.LP-FAQ .FAQ-title i');

        //show faq content clicking on faqTrigger
        faqTrigger.on('click', function(event){
            event.preventDefault();
            $(this).next('.FAQ-content').slideToggle(100).end().parent('li').toggleClass('content-visible');
        });
    }
});

function callSidebarClickEvent(ele_id) {
    $(`.Longform-sidebar ul li a[href="#${ele_id}"]`).click();
}

$(function() {

    if($('html').hasClass('hs-inline-edit') == false) {

      var url = $(location).attr('href');
      var ele_id = '';

      if(url.indexOf('#') != -1) {
        var arr_url = url.split('#');
        ele_id = arr_url[arr_url.length - 1];
      } else if(url.indexOf('?w_id') != -1) {
        ele_id = getQueryString('w_id');
      }
        
        if($(`.Longform-sidebar ul li a[href="#${ele_id}"]`).length > 0) {
            setTimeout(callSidebarClickEvent, 200, ele_id);
        } else {

            if($(`#${ele_id}`).length > 0) {
                console.log('goToAnchor id: ' + ele_id);
                var $target = $(`#${ele_id}`);
                var headerOffset = -80;

                if($('body>.Page.LP-Basic').length > 0) {
                  headerOffset = 0;
                }

                var top = $target.offset().top + headerOffset;
                setTimeout(goToAnchor, 200, top);
            }
        } 
        
      }
});

function goToAnchor(offset) {
    $('html, body').animate({
        scrollTop: `${offset}px`
    }, 400);
}

function getQueryString(query) {
    query = query.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var expr = "[\\?&]"+query+"=([^&#]*)";
    var regex = new RegExp( expr );
    var results = regex.exec( window.location.href );
    if ( results !== null ) {
        return results[1];
    } else {
        return false;
    }
}
