import 'mutationobserver-polyfill'


let $ = jQuery


class ResourceForm {
	constructor(ele) {
		this.$form = $(ele)
		this.loaded = false
		this.submitted = false
		
		this.$form.on('submit', 'form', () => this.submitted = true)
		this.$form.on('click', 'button, input[type=submit]', () => this.submitted = true)
		
		this.observer = new MutationObserver(this.handleMutations.bind(this))
		this.observer.observe( this.$form[0], {
			childList: true,
			subtree: true
		})
	}
	
	handleMutations(mutations) {
		if(this.loaded == false) {
			for(let mutation of mutations) {
				for(let node of mutation.addedNodes) {				
					if(node.tagName == 'FORM') {
						this.loaded = true
					}
				}
			}
		} else if(this.submitted == true) {
			for(let mutation of mutations) {
				for(let node of mutation.addedNodes) {
					if(node.tagName == 'FORM') {
						this.handleRedirect()
						this.observer.disconnect()
					}
				}
			}
		}
	}
	
	handleRedirect() {
		this.$form
			.empty()
			.append($('<div>').addClass('LoadingIcon--gray'))
			
		window.location = `${window.location.pathname}/thank-you`
	}
}


$(() => $('.Resource-form').each((index, ele) => new ResourceForm(ele)))
