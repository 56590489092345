let $ = jQuery


class HowItWorksDiagram {
	constructor(ele) {
		this.$ele = $(ele)
		this.$diagram = $('.HowItWorksSvg', this.$ele)
		this.$anchors = $('.HowItWorksDiagram-nav a', this.$ele)
		
		this.$anchors.eq(0).addClass('is-active')
		$(this.$anchors.eq(0).attr('href')).addClass('is-active')
		
		this.$anchors.each((index, ele) => {
			$(ele).data('index', index)
		})
		
		this.$anchors.on('click', this.onClick.bind(this))
		
		this.$ele.attr('class', `HowItWorksDiagram is-initialized is-waiting is-stage1`)
		this.$diagram.attr('class', `HowItWorksSvg is-stage1`)
	}
	
	onClick(e) {
		e.preventDefault()
		
		this.$anchors.each((index, ele) => {
			$(ele).removeClass('is-active')
			$($(ele).attr('href')).removeClass('is-active')
		})
		
		$(e.currentTarget).addClass('is-active')
		$($(e.currentTarget).attr('href')).addClass('is-active')
		
		let index = $(e.currentTarget).data('index')
		
		this.$ele.attr('class', `HowItWorksDiagram is-initialized is-stage${index + 1}`)
		this.$diagram.attr('class', `HowItWorksSvg is-stage${index + 1}`)
	}
}


$(() => $('.HowItWorksDiagram').each((index, ele) => new HowItWorksDiagram(ele)))
