let $ = jQuery
let $window = $(window)

$(document).ready(function() {
    unwrapCustomModule();
    changeRequiredFieldText();

    initOverlayForm();
});

function initOverlayForm() {
    if($('.LP-Form.overlay').length > 0) {

        waitForEl('.LP-Form.overlay .hs-form', function() {
            $('.LP-Form.overlay .hs-form select').change(function() {
                if($(this).val() != '-1') {
                  $(this).addClass('white-color');
                } else {
                  $(this).removeClass('white-color');
                }
            });
        });
    }
}

function unwrapCustomModule() {
    if($('.LP-Custom-Module').length > 0) {
        $('.LP-Custom-Module').each(function() {
            if($(this).parent().hasClass('hs_cos_wrapper_type_module')) {
                $(this).unwrap();
            }
        });
    }
}

function changeRequiredFieldText() {
    if($('.LP-Form .hs_cos_wrapper').length > 0) {
        waitForEl('.LP-Form .hs_cos_wrapper .hs-form', function() {
            $('.LP-Form .hs_cos_wrapper .hs-form-required').text('必須');
        });
    }
    if($('#Blog-Sidebar .blog-subscript').length > 0) {
        waitForEl('#Blog-Sidebar .blog-subscript .hs-form', function() {
            $('#Blog-Sidebar .blog-subscript .hs-form-required').text('必須');
        });
    }
    if($('.LP-Form .form_inbound').length > 0) {
        waitForEl('.LP-Form .form_inbound .hs-form', function() {
            $('.LP-Form .form_inbound .hs-form-required').text('必須');
        });
    }
}

function bindAnchorLinkClickEvent() {
    $('a[href^="#"]').on('click', e => {
    
        let target = e.target.hash.slice(1)
        let $ele = $(`#${target}`)
        
        if($ele.length) {
            e.preventDefault()
        
            let offset = $ele.offset().top
            
            $('html, body').animate({
                scrollTop: `${offset}px`
            }, 400)
            
            if(target.slice(0, 6) != 'block-' && 'pushState' in history) {
                history.pushState({}, '', e.target.href)
            }
        }
        
    })
}

var waitForEl = function(selector, callback) {
  if ($(selector).length) {
    callback();
  } else {
    setTimeout(function() {
      waitForEl(selector, callback);
    }, 100);
  }
};
