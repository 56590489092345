let $ = jQuery
let _registered = []


$(document).on('ready', () => {
	for(let item of _registered) {
		item.init()
	}
})


class CustomerQuotes {
	constructor(ele) {
		this.$ele = $(ele)
		_registered.push(this)
	}
	
	init() {
		this.$ele.cycle({
			'slides': '> .CustomerQuotes-item',
			'next': '> .CustomerQuotes-next',
			'prev': '> .CustomerQuotes-prev',
			'timeout': 5000
		})
		
		this.$ele.on('cycle-next cycle-prev', e => {
			$(e.currentTarget).cycle('pause')
		})
	}
}


$(() => $('.CustomerQuotes').each((index, ele) => new CustomerQuotes(ele)))
