let $ = jQuery
let $window = $(window)
let _registered = []


$window.on('load', () => {
	for(let item of _registered) {
		item.onLoad()
	}
})


class TileCards {
	constructor(ele) {
		this.$tiles = $('.TileCard', ele)
		_registered.push(this)
	}
	
	onLoad() {
		let maxHeight = 0
		
		$('.TileCard-icon > img', this.$tiles).each((index, ele) => {
			if($(ele).outerHeight() > maxHeight) {
				maxHeight = $(ele).outerHeight()
			}
		})
		
		$('.TileCard-icon', this.$tiles).css({
			'max-height': `${maxHeight}px`
		})
	}
}


$(() => $('.TileCardsSection').each((index, ele) => new TileCards(ele)))
