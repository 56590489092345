let $ = jQuery

if($('.VideoModal').length > 0) {
    $('.VideoModal').magnificPopup({
    	disableOn: 640,
    	type: 'iframe',
    	removalDelay: 200,
    	fixedContentPos: false
    });
}
