let $ = jQuery


class Tabber {
	constructor(ele) {
		this.$ele = $(ele)
		this.$anchors = $('.Tabber-nav a', this.$ele)
		
		this.$anchors.eq(0).addClass('is-active')
		$(this.$anchors.eq(0).attr('href')).addClass('is-active')
		
		this.$anchors.on('click', this.onClick.bind(this))
		
		this.$ele.addClass('is-initialized')
	}
	
	onClick(e) {
		e.preventDefault()
		
		this.$anchors.each((index, ele) => {
			$(ele).removeClass('is-active')
			$($(ele).attr('href')).removeClass('is-active')
		})
		
		$(e.currentTarget).addClass('is-active')
		$($(e.currentTarget).attr('href')).addClass('is-active')
	}
}


$(() => $('.Tabber').each((index, ele) => new Tabber(ele)))
