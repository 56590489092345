import Pacnav from './pacnav'


class MsPacnav {
	constructor() {
		this.callbacks = {}
	}
	
	on(action, callback) {
		this.callbacks[action] = this.callbacks[action] || []
		this.callbacks[action].push(callback)
	}
	
	onReady(menu) {
		if('ready' in this.callbacks) {
			for(let callback of this.callbacks.ready) {
				callback(menu.id)
			}
		}
	}
	
	onToggle(menu) {
		if('toggle' in this.callbacks) {
			for(let callback of this.callbacks.toggle) {
				callback(menu.id)
			}
		}
	}
	
	getMenus() {
		return _registered
	}
}


export default new MsPacnav()
