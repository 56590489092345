let $ = jQuery
let _registered = []


class PartnerTeaser {
	constructor(ele) {
		this.isVisible = false
		this.nid = $(ele).data('partner')
		this.$teaser = $(ele)
		this.$tray = $(`.PartnerTray[data-partner="${this.nid}"]`)
		
		this.$teaser.addClass('is-attached')
		
		this.$teaser.on('click', this.onToggle.bind(this))
		$('.PartnerTray-close', this.$tray).on('click', this.onClose.bind(this))
		
		_registered.push(this)
	}
	
	hideTray() {
		if(this.isVisible == true) {
			this.isVisible = false
			this.$teaser.removeClass('is-active')
			this.$tray.removeClass('is-active')
		}
	}
	
	
	showTray() {
		if(this.isVisible == false) {
			this.isVisible = true
			this.$teaser.addClass('is-active')
			this.$tray.addClass('is-active')
			
			$('html, body').animate({
				scrollTop: `${this.$teaser.offset().top - 100}px`
			}, 400)
		}
	}
	
	onToggle(e) {
		e.preventDefault()
		
		let isVisible = this.isVisible
		
		for(let item of _registered) {
			item.hideTray()
		}
		
		if(!isVisible) {
			this.showTray()
		}
	}
	
	onClose(e) {
		e.preventDefault()
		this.hideTray()
	}
}


$('body').on('click', '.PartnerTeaser', (e) => {
	e.preventDefault()
	
	let $ele = $(e.currentTarget)
	
	if(!$ele.hasClass('is-initialized')) {
		for(let item of _registered) {
			item.hideTray()
		}
		
		let teaser = new PartnerTeaser($ele)
		teaser.showTray()
	}
})


if(location.hash) {
	let hash = location.hash.slice(1)
	let $target = $(`[data-hash="${hash}"]`)
	
	if($target.length) {
		$(document).ready(() => {		
			let id = $target.data('partner')
			$(`#node-${id}`).trigger('click')
		})
	}
}
