/* ===================================================================
OBPM Menu
=================================================================== */
$(document).ready(function() {
	if($('#primary-nav .hs-menu-depth-1 > a[href$="/solution"]').length > 0) {
		$('#primary-nav .hs-menu-depth-1 > a[href$="/solution"]').parent().addClass('child-large');
	}

    fixScrollTableOnMobile();
});

/* ===================================================================
Blog - Table
=================================================================== */

function fixScrollTableOnMobile() {
    if($('#Blog-Page #Main_Content .Blog-Detail').length > 0) {

        var min_width_td = 130;
        var windowWidth = $(window).width();

        $('#Blog-Page #Main_Content .Blog-Detail table').each(function() {
           $(this).wrap('<div class="table-wrap"></div>'); 
        });


    }
}

/* ===================================================================
LP-List-Case
=================================================================== */

var max_case_item_box = 0;

function isResizeCaseItem() {
	var max_height = 0;
    $('.LP-List-Case .Row > .Column--4').each(function() {
    	var item_height = $(this).height();
    	if(item_height > max_height) {
    		max_height = item_height;
    	}
    });

    if(max_case_item_box != max_height) {
    	max_case_item_box = max_height;
    	return true;
    }
    return false;
}
function resizeCaseListItem() {

	if(isResizeCaseItem()) {
		$('.LP-List-Case .Row > .Column--4 .ListCaseItem-content').removeAttr('style');
		var max_height = 0;
	    $('.LP-List-Case .Row > .Column--4').each(function() {
	    	var item_height = $(this).find('.ListCaseItem-content').height();
	    	if(item_height > max_height) {
	    		max_height = item_height;
	    	}
	    });

		$('.LP-List-Case .Row > .Column--4 .ListCaseItem-content').css('height', max_height);
	}
}

$(function() {
    if($('.LP-List-Case').length > 0) {
    	resizeCaseListItem();

    	$(window).resize(function() {
    		resizeCaseListItem();
    	})
    }
});