let $       = jQuery
let $window = $(window)
let $body   = $('html, body')


$('.Sidebar').each((index, ele) => {
		
	let $sidebar      = $(ele)
	let $sidebarItems = $('li', ele)
	let $wrap         = $(ele).parent()
	let $mainContent  = $(ele).parent().parent().find('.Longform-body')
	
	let headerOffset  = -80
	let sidebarHeight = 0
	let sidebarOffset = 0
	let scrollOffset  = 0
	let windowHeight  = 0
	let wrapHeight    = 0
	let wrapOffset    = 0
	let mainContentHeight = 0
	let titleOffsets  = []
	
	let getPosition = () => {
		sidebarHeight = $sidebar.outerHeight()
		sidebarOffset = $sidebar.offset().top
		scrollOffset  = $window.scrollTop()
		windowHeight  = $window.height()
		wrapHeight    = $wrap.outerHeight()
		wrapOffset    = $wrap.offset().top

		$mainContent.addClass('test');
		
		titleOffsets = []

		console.log('sidebarHeight : ' + sidebarHeight)
		console.log('wrapHeight : ' + wrapHeight)
		console.log('mainContentHeight : ' + mainContentHeight)
		
		$sidebarItems.each(function() {
			let href = $('a', this).attr('href')

			if(href.indexOf('#') == 0) {

				let $target = $($('a', this).attr('href'))
				titleOffsets.push({
					$link: $('a', this),
					$item: $(this),
					offset: $target.offset().top + $target.outerHeight()
				})
			}
		})
	}
	
	let getFinal = () => {
		wrapHeight = $wrap.outerHeight()
		//return headerOffset + wrapOffset + wrapHeight - sidebarHeight
		
		mainContentHeight = $mainContent.outerHeight()

		if(mainContentHeight <= sidebarHeight) {
			return headerOffset + wrapOffset + wrapHeight - wrapHeight
		} else {
			return headerOffset + wrapOffset + wrapHeight - sidebarHeight
		}
	}
	
	let getInitial = () => {
		return headerOffset + wrapOffset
	}
	
	let init = () => {
		$window.on('load resize', getPosition)
		$window.on('load scroll', update)
		$('a', $sidebarItems).on('click', setActive)
	}
	
	let setActive = (event) => {
		let $this = $(event.currentTarget)
		if($($this.attr('href')).length > 0) {
			let $target = $($this.attr('href'))
			
			event.preventDefault()
			
			$body.animate({
				scrollTop: $target.offset().top + headerOffset - 40
			}, 400)
		}
	}
	
	let update = () => {
		scrollOffset = $window.scrollTop()

		if(scrollOffset >= getFinal()) {

			$sidebar.addClass('is-final')
			$sidebar.removeClass('is-fixed')
			$sidebar.removeClass('is-initial')
		} else if(scrollOffset >= getInitial()) {
			$sidebar.addClass('is-initial')
			$sidebar.removeClass('is-final')
			$sidebar.removeClass('is-fixed')
		} else {
			$sidebar.removeClass('is-final')
			$sidebar.removeClass('is-fixed')
			$sidebar.removeClass('is-initial')
		}

		
		for(let title of titleOffsets) {
			if(title.offset > scrollOffset - headerOffset) {
				$sidebarItems.removeClass('is-active')
				title.$item.addClass('is-active')
				break
			}
		}
		
	}
	
	init();
	var is_click_default = true;

	if($('html').hasClass('hs-inline-edit') == false) {
      var url = $(location).attr('href');
          if(url.indexOf('#') != -1) {
            var arr_url = url.split('#');
            var ele_id = arr_url[arr_url.length - 1];
            if($(`.Longform-sidebar ul li a[href="#${ele_id}"]`).length > 0) {
            	is_click_default = false;	
            }
          }
      }

     if(is_click_default) {
		$sidebarItems.eq(0).addClass('is-active');
	}

})
